<template>
  <a-spin :spinning="surveySaving" size="large">
    <div class="cluster-assignment" :class="{ disabled: clusterCount === 0 || surveySaving }">
      <div class="cluster-header flex justify-space-between">
        <div class="txt-18 txt-font-din-medium txt-bold txt-black flex align-center">
          {{ $t('components.labels.clusterAssignment') }}
          <a-tooltip
            class="ml-8"
            :title="$t('components.toolTips.clusterAssignment')">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </div>
        <a-button
          type="primary"
          :disabled="clusterCount === 0 || isSupervisor"
          :loading="saving"
          size="large"
          data-cy="assign-cluster-btn"
          @click="assign">
          {{ $t('components.description.assignCluster') }}
        </a-button>
        <AssignmentConfirmationModal
          v-if="confirmationModalActive"
          :active="confirmationModalActive"
          :on-complete="calculateAssignment"
          :on-exit="onExit"/>
      </div>
      <div class="cluster-table">
        <div class="table-header flex justify-space-between pt-10 pl-10 pr-10 pb-10">
          <div class="txt-16">
            {{ $t('components.description.totalPopulationSize') }}
            <a-input-number v-model="clusterCumulativePopulation" size="large" :disabled="true" class="ml-8 w101" data-cy="total-population"/>
          </div>
          <div class="flex align-center justify-center">
            <a-tooltip :title="$t('components.toolTips.upload')">
              <a-upload
                :file-list="fileList"
                :disabled="isSupervisor"
                :custom-request="() => {}"
                accept=".csv, .xlsx, text/csv, application/csv,
                  text/comma-separated-values, application/csv, application/excel"
                :before-upload="beforeUpload">
                <a-button type="link" :disabled="isSupervisor" class="pl-8 pr-8 flex align-center" :class="{ 'assignment-action-disabled': isSupervisor, 'assignment-action': !isSupervisor }" data-cy="upload-cluster-btn">
                  <embed src="/Upload.svg" :style="{ width: '38px', pointerEvents: 'none', opacity: isSupervisor ? 0.4 : undefined }">
                </a-button>
              </a-upload>
            </a-tooltip>
            <a-tooltip :title="$t('components.toolTips.paste')">
              <a-button type="link" class="pl-8 pr-8 flex align-center" :class="{ 'assignment-action-disabled': isSupervisor, 'assignment-action': !isSupervisor }"
                data-cy="paste-cluster-btn" :disabled="isSupervisor" @click="paste">
                <embed :disabled="isSupervisor" src="/Copy.svg" :style="{ width: '24px', pointerEvents: 'none' }">
              </a-button>
            </a-tooltip>
            <a-tooltip :title="$t('components.toolTips.download')">
              <a-button type="link" class="pl-8 pr-8 flex align-center assignment-action"
                data-cy="download-cluster-btn" @click="download">
                <embed :disabled="isSupervisor" src="/Doc.svg" :style="{ width: '23px', pointerEvents: 'none' }">
              </a-button>
            </a-tooltip>
            <a-tooltip :title="$t('components.toolTips.clear')" data-cy="clear-cluster-btn">
              <a-button type="link" class="pl-8 pr-8 flex align-center" :class="{ 'assignment-action-disabled': isSupervisor, 'assignment-action': !isSupervisor }"
                :disabled="isSupervisor"
                @click="clear">
                <a-icon :disabled="isSupervisor" type="delete" theme="filled" class="delete-icon"/>
              </a-button>
            </a-tooltip>
          </div>
        </div>
        <!--
          className="" is to fix a weird issue where hot-table breaks Vue DevTools,
          see https://github.com/vuejs/devtools/issues/1947#issuecomment-1299134339 for details
        -->
        <hot-table
          ref="hot"
          class="table-body"
          class-name=""
          :data="data"
          width="635"
          height="450"
          stretch-h="all"
          :col-headers="[
            $t('components.description.geographicalUnit'),
            $t('components.description.populationSize'),
            $tc('components.description.cluster', 1),
            $t('components.description.addLocation'),
            $t('components.description.id'),
          ]"
          :custom-borders="customBorders"
          license-key="non-commercial-and-evaluation"
          :settings="hotSettings">
            <hot-column :placeholder="$t('components.description.enterGeographicalUnit')" :read-only="isSupervisor ? 'true' : undefined"/>
            <hot-column width="50px" type="numeric" :read-only="isSupervisor ? 'true' : undefined" :placeholder="$t('components.description.enterPopulationSize')"/>
            <hot-column read-only="true"/>
            <hot-column>
              <LocationColumn :metadata="metadata" hot-renderer/>
            </hot-column>
            <hot-column read-only="true"/>
        </hot-table>
      </div>
      <div class="cluster-footer txt-grey">
        * RC: {{ $t('components.description.reserveCluster') }}
      </div>
    </div>
  </a-spin>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import { mapActions, mapGetters, mapState } from 'vuex';
import { HotTable, HotColumn } from '@handsontable/vue';
import Handsontable from 'handsontable';
import debounce from 'lodash/debounce';
import LocationColumn from './location-column.vue';
import { configForPossibleBackendRequest } from '../../../../util/request';
import { geoUnitsToTableData } from '../../../../util/clusters';

export default {
  name: 'MetadataClusterAssignment',
  components: {
    LocationColumn,
    HotTable,
    HotColumn,
    AssignmentConfirmationModal: () => import('./assignment-confirmation-modal.vue')
  },
  props: {
    size: {
      type: Number,
      required: true
    },
    clusterCount: {
      type: Number,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      testingInfo: undefined,
      fileList: [],
      saving: false,
      confirmationModalActive: false,
      changedRowIndices: new Set(),
      data: [],
      hotSettings: {
        allowRemoveColumn: false,
        allowRemoveRow: false,
        autoRowSize: false,
        autoColumnSize: false,
        fillHandle: false,
        minSpareRows: 1,
        minRows: 25,
        locale: 'en-US',
        hiddenColumns: {
          columns: [4],
          indicators: false,
          copyPasteEnabled: false
        },
        beforePaste(data, coords) {
          const { endRow } = coords[0];
          const rows = this.getData();
          const newRowLength = data.length + rows.length;
          if (newRowLength > endRow) {
            const addCount = (newRowLength - endRow) - 1;
            this.batchRender(() => {
              this.alter('insert_row', rows.length, addCount);
            });
          }
        },
        afterPaste() {
          this.scrollViewportTo(0, 0);
          this.deselectCell();
        },
        beforeChange(changes) {
          for (let i = 0; i < changes.length; i += 1) {
            const [row, column, oldValue, newValue] = changes[i];

            // If column is location, do not cast to integer
            if (column === 3) {
              break;
            }

            try {
              const replacedValue = newValue.replaceAll?.(/[,\s]*/g, '');
              if (column === 1) {
                const parsedValue = parseInt(replacedValue, 10);
                // eslint-disable-next-line no-param-reassign
                changes[i] = [row, column, oldValue, (Number.isInteger(parsedValue)) ? parsedValue : 0];
              } else {
                // eslint-disable-next-line no-param-reassign
                changes[i] = [row, column, oldValue, replacedValue];
              }
            } catch (err) {
              // do nothing
            }
          }
        },
        afterChange: (changes, source) => {
          if (this.$refs.hot) {
            const data = this.getData(this.$refs.hot);
            const assignments = data
              .filter(x => x.population !== null || x.population !== undefined);

            // Disabled column 3 if assignment is null or an empty string
            assignments
              .map(assignment => assignment.clusters)
              .forEach((assignment, index) => {
                const isDisabled = assignment === '' || assignment === null;
                this.$refs.hot.hotInstance.setCellMeta(index, 3, 'readOnly', isDisabled);
              });

            if (source !== 'loadData' && source !== 'updateData') {
              changes.forEach((change) => {
                this.changedRowIndices.add(change[0]);
              });
            }
          }
        },
        afterDeselect: debounce(() => {
          this.saveChanges();
        }, 100)
      },
      customBorders: [
        {
          range: {
            from: {
              row: 0,
              col: 0
            },
            to: {
              row: 50,
              col: 0
            }
          },
          left: {
            width: 0,
            color: 'white'
          },
          right: {
            width: 0,
            color: 'white'
          }
        },
        {
          range: {
            from: {
              row: 0,
              col: 0
            },
            to: {
              row: 50,
              col: 1
            }
          },
          left: {
            width: 0,
            color: 'white'
          },
          right: {
            width: 0,
            color: 'white'
          }
        },
        {
          range: {
            from: {
              row: 0,
              col: 0
            },
            to: {
              row: 50,
              col: 2
            }
          },
          left: {
            width: 1,
            color: 'white'
          },
          right: {
            width: 1,
            color: 'white'
          }
        },
        {
          range: {
            from: {
              row: 0,
              col: 0
            },
            to: {
              row: 50,
              col: 3
            }
          },
          left: {
            width: 1,
            color: 'white'
          },
          right: {
            width: 1,
            color: 'white'
          }
        }
      ]
    };
  },
  computed: {
    ...mapState({
      project: state => state.survey.project,
      metadata: state => state.survey.metadata,
      geoUnits: state => state.survey.geoUnits,
      clusters: state => state.survey.clusters,
      surveySaving: state => state.survey.saving,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    ...mapGetters([
      'clusterCumulativePopulation',
      'clustersHaveAssignments'
    ]),
    isInvalid() {
      if (this.clusterCumulativePopulation === 0) {
        return false;
      }

      if (this.metadata.surveyType === 'Rapid Smart') {
        return false;
      }

      // if calculated cumulative under cluster is 10K or less
      // and the pop size under sample size is more than 10K → display error
      if (this.clusterCumulativePopulation <= 10000 && this.size > 10000) {
        return true; // show error
      }

      // if calculated cumulative under cluster is  more than 10K
      // and the pop size under sample size is 10K or less → display error
      if (this.clusterCumulativePopulation > 10000 && this.size <= 10000) {
        return true; // show error
      }

      return false;
    }
  },
  mounted() {
    if (this.geoUnits.length > 0) {
      this.setGeoUnitClustersInTable(this.geoUnits);
    }
  },
  beforeDestroy() {
    this.saveChanges();
  },
  methods: {
    ...mapActions(['setGeoUnitClusters', 'setMetadata', 'getClusterAssignments']),
    getData(ref) {
      return ref.hotInstance
        .getData()
        .filter(row => row[0] || row[1] || row[4])
        .map(([unitName, population, clusters, location, id]) => ({
          unitName,
          location,
          population,
          clusters,
          id
        }));
    },
    assign() {
      if (this.clustersHaveAssignments) {
        this.confirmationModalActive = true;
        return;
      }
      this.calculateAssignment();
    },
    async calculateAssignment(justification) {
      try {
        const assignments = this.getData(this.$refs.hot);
        if (assignments.length === 0) {
          this.$notification.error({
            message: this.$t('components.notifications.error'),
            description:
              this.$t('components.notifications.errorEnterDataBeforeAssignClusters')
          });
          return;
        }
        this.saving = true;
        const payload = {
          assignments,
          count: this.clusterCount,
          population: this.size
        };
        const baseConfig = { method: 'post', url: `/projects/${this.project.id}/calculate-clusters`, data: payload };
        const token = this.$store.getters.token ? this.$store.getters.token : null;
        const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        if (data) {
          if (Array.isArray(data) && data.length > 0) {
            this.testingInfo = this.$t('components.description.reserveClusterTestingInfo', {
              number: `${data[0].random}`,
              numberTwo: `${data[0].reserveClusters.join(', ')}`
            });
            // TODO: REMOVE ME ONCE WE PASS QA
            // Shape geoUnits for API
            const geoUnits = data
              .reduce((accum, { clusters: clusterNames, location, population, unitName, id }) => {
                if (!clusterNames || clusterNames.length === 0) {
                  return [...accum, { clusters: '', location, population, unitName, id }];
                }
                return [...accum, { clusters: clusterNames.join(', '), location, population, unitName, id }];
              }, []);

            this.setGeoUnitClustersInTable(geoUnits);
            this.save(this.getData(this.$refs.hot), justification, true, true);
            this.$notification.success({
              message: this.$t('components.notifications.success'),
              description:
                this.$t('components.notifications.assignedClusters')
            });
          }
        } else {
          this.$alert().danger(this.$t('components.notifications.errorAssignCluster'));
        }
      } catch (error) {
        this.$alert().danger(this.$t('components.notifications.errorCalculateClusters'));
      } finally {
        this.onExit();
        this.saving = false;
      }
    },
    onExit() {
      this.confirmationModalActive = false;
    },
    beforeUpload(file) {
      this.$refs.hot.hotInstance.clear();
      const fileNameLowercased = file.name.toLowerCase();
      if (fileNameLowercased.endsWith('.xlsx') || fileNameLowercased.endsWith('.xls')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.setTableFromFileUpload(data);
        };
        reader.readAsBinaryString(file);
      } else {
        Papa.parse(file, {
          worker: true,
          complete: (result) => this.setTableFromFileUpload(result.data)
        });
      }
    },
    setTableFromFileUpload(result) {
      try {
        let data;
        const filteredResults = result.filter(row => row[0] !== '');
        const mapRows = ([unitName, population, clusters, location]) => {
          const unitText = unitName == null ? '' : unitName.toString();
          return [unitText.trim(), parseInt(population, 0), clusters, location];
        };
        if (filteredResults && filteredResults[0][0] === 'Geographical Unit') {
          const [, ...temp] = filteredResults;
          data = temp
            .filter(row => row.length >= 4)
            .map(mapRows);
        } else {
          data = filteredResults.map(mapRows);
        }
        this.data = Handsontable.helper.createEmptySpreadsheetData(data.length + 1, 5);
        this.$refs.hot.hotInstance.loadData(data);
        this.save(this.getData(this.$refs.hot));
        this.$notification.success({
          message: this.$t('components.notifications.success'),
          description:
            this.$t('components.notifications.importedFromFile')
        });
      } catch (err) {
        this.$notification.error({
          message: this.$t('components.notifications.error'),
          description:
            this.$t('components.notifications.errorImportingCSV')
        });
      }
    },
    async paste() {
      await this.clear();
      const plugin = this.$refs.hot.hotInstance.getPlugin('copyPaste');
      const clipboard = await navigator.clipboard.readText();
      this.$refs.hot.hotInstance.selectCell(0, 0);
      plugin.paste(clipboard);
      this.$refs.hot.hotInstance.deselectCell();
    },
    download() {
      const exporter = this.$refs.hot.hotInstance.getPlugin('exportFile');
      exporter.downloadFile('csv', {
        filename: 'cluster-assignment',
        exportHiddenRows: true,
        exportHiddenColumns: false,
        columnHeaders: true
      });
    },
    async clear() {
      const assignments = this.getData(this.$refs.hot);
      await this.setGeoUnitClusters({
        geoUnits: assignments.map(geoUnit => ({ id: geoUnit.id }))
      });
      this.$refs.hot.hotInstance.loadData([]);
      this.getClusterAssignments();
      this.data = Handsontable.helper.createEmptySpreadsheetData(25, 5);
    },
    // eslint-disable-next-line prefer-arrow-callback
    async save(data, justification, updateClusters = true, force = false) {
      if (force || (!this.saving && !this.surveySaving)) {
        this.changedRowIndices.clear();
        const geoUnits = data
          .reduce((accum, { clusters: clusterNames, location, population, unitName, id }) => {
            if (!clusterNames || clusterNames.length === 0) {
              return [...accum, { clusters: [], location, population, unitName, id: id || undefined }];
            }
            const clusters = clusterNames
              .split(', ')
              .map(clusterName => ({ name: clusterName, location, population, unitName }));
            return [...accum, { clusters, location, population, unitName, id: id || undefined }];
          }, []);
        if (geoUnits.length > 0) {
          await this.setGeoUnitClusters({
            geoUnits,
            justification,
            updateClusters
          });
          if (this.geoUnits.length > 0) {
            this.setGeoUnitClustersInTable(this.geoUnits);
          }
          this.getClusterAssignments();
        }
      }
    },
    setGeoUnitClustersInTable(geoUnits) {
      if (this.$refs && this.$refs.hot) {
        // Check for duplicate unitNames
        const data = geoUnitsToTableData(geoUnits);
        this.data = cloneDeep(data);
        this.$refs.hot.hotInstance.loadData(cloneDeep(data));
      }
    },
    saveChanges() {
      if (this.$refs && this.$refs.hot && this.changedRowIndices.size) {
        const data = this.getData(this.$refs.hot);
        const changedRows = [];
        this.changedRowIndices.forEach((change) => {
          const row = data[change];
          if (row) changedRows.push(row);
        }, []);
        this.save(changedRows, undefined, false);
      }
    }
  }
};
</script>

<style lang="scss">
.cluster-assignment {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .handsontable .htRight {
    text-align: left;
  }

  .assignment-action:hover {
    embed {
      opacity: 0.4;
    }
  }

  .assignment-action-disabled {
    opacity: 0.4;
  }

  .handsontable th,
  .handsontable td,
  .handsontableInput {
    line-height: 30px;
    padding-left: 15px;
  }

  .handsontable th {
    font-size: 13px;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding-left: 10px !important;
    overflow-wrap: break-word;
    white-space: break-spaces;
    padding: 2% 2px 2px 2px;

    &:first-child {
      border-left: none;
    }
  }

  .handsontable td {
    overflow-wrap: break-word;
    white-space: break-spaces;
    border-left: none !important;
    border-right: none !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .cluster-header {
    align-items: center;
    grid-area: 1 / 1 / 2 / 6;
    margin-bottom: 10px;
  }

  .cluster-table {
    grid-area: 2 / 1 / 5 / 6;
    overflow: hidden;
    // min-height: 450px;
    border-bottom: solid 1px #cccccc;
  }

  .table-header {
    border-radius: 1px;
    border: solid 1px #cccccc;
    border-bottom: none;
  }

  .table-body {
    border-radius: 1px;
    border: solid 1px #cccccc;
    border-top: none;
  }

  .cluster-footer { grid-area: 5 / 1 / 6 / 6; }

  td.disabled .location-column {
    display: none;
  }

  .location-column button,
  .handsontable .htPlaceholder {
    font-size: 12px;
  }

  .delete-icon {
    font-size: 32px;
    margin-top: 5px;
    color: #c6c7c6;
  }

  .htCore tbody tr:last-child td {
    border-bottom: none;
  }
}
</style>
