<template>
  <div v-if="isDisabled" class="location-column">
    <a-button :disabled="isSupervisor" class="pl-0 pr-0 w100" type="link" :title="$t('components.description.addLocation')" @click="openModal">
      <span v-if="!value" class="location-cell" :title="$t('components.description.addLocation')">
        {{ $t('components.description.addLocation').replace('géographique', '') }}
      </span>
      <span v-else class="txt-13">
        {{ value }}
      </span>
    </a-button>
    <GeographicUnitModal
      v-if="isModalActive"
      :initial-lat="initialLat"
      :initial-lng="initialLng"
      :active="isModalActive"
      :on-exit="onModalExit"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MetadataLocatonColumn',
  components: {
    GeographicUnitModal: () => import('../../geographic-unit-modal/geographic-unit-modal.vue')
  },
  props: {
    metadata: {
      type: Object,
      default: () => ({ latitude: undefined, longitude: undefined })
    }
  },
  data() {
    return {
      // We'll need to define properties in our data object,
      // corresponding to all of the data being injected from
      // the BaseEditorComponent class, which are:
      // - hotInstance (instance of Handsontable)
      // - row (row index)
      // - col (column index)
      // - prop (column property name)
      // - TD (the HTML cell element)
      // - cellProperties (the cellProperties object for the edited cell)
      hotInstance: null,
      TD: null,
      row: null,
      col: null,
      prop: null,
      value: null,
      cellProperties: {
        readOnly: true
      },
      isModalActive: false
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    isDisabled() {
      if (!this.hotInstance.isDestroyed) {
        return this.hotInstance.getDataAtCell(this.row, 2);
      }
      return false;
    },
    initialLat() {
      if (this.value) {
        const [lat] = this.value.split(', ');
        return parseFloat(lat);
      }

      const localLatitude = window.localStorage.getItem('clusterAssignmentLatitude');
      if (localLatitude) {
        return parseFloat(localLatitude);
      }

      return this.metadata.latitude || -1.286389;
    },
    initialLng() {
      if (this.value) {
        const [, lng] = this.value.split(', ');
        return parseFloat(lng);
      }

      const localLongitude = window.localStorage.getItem('clusterAssignmentLongitude');
      if (localLongitude) {
        return parseFloat(localLongitude);
      }

      return this.metadata.longitude || 36.817223;
    }
  },
  methods: {
    openModal() {
      this.isModalActive = true;
    },
    onModalExit(result) {
      this.isModalActive = !this.isModalActive;
      if (result) {
        if (result.lat && result.lng) {
          window.localStorage.setItem('clusterAssignmentLatitude', result.lat);
          window.localStorage.setItem('clusterAssignmentLongitude', result.lng);
        }
        try {
          this.hotInstance.setDataAtCell(this.row, this.col, `${result.lat.toFixed(6)}, ${result.lng.toFixed(6)}`);
        } catch (err) {
          this.hotInstance.setDataAtCell(this.row, this.col, `${result.lat}, ${result.lng}`);
        }
      }
    }
  }
};
</script>

<style scoped>
.location-cell {
  display: block;
  width: 100%;
  overflow-wrap: break-word;
}
.location-column >>> button {
  text-align: left;
  overflow-wrap: break-word;
  white-space: break-spaces;
  line-height: 30px;
  height: fit-content;
}
</style>
